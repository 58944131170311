let button = document.querySelectorAll('.pricing__button');
let select = document.querySelector('#piano');

button.forEach(element => {
    if (element != null && element.getAttribute('data-pricing')) {
        
        element.addEventListener('click', function() {
            dataPricing = element.getAttribute('data-pricing');
            select.value = dataPricing;
        })
    }
});